import React from 'react'
import PropTypes from 'prop-types'
import {graphql, Link, StaticQuery} from 'gatsby'
import {FiChevronRight, FiClock} from 'react-icons/fi'
import Img from "gatsby-image";
import SearchInput, {createFilter} from 'react-search-input'
import Dropdown from "react-dropdown";

/* eslint-disable */
class BlogRoll extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      searchTerm: '',
      selectedCategory: ''
    }
    this.searchUpdated = this.searchUpdated.bind(this)
    this.selectCategory = this.selectCategory.bind(this)
  }

  render() {
    const {data} = this.props
    const {edges: posts} = data.allMarkdownRemark
    const categories = [...new Set(posts.map(post => post.node.frontmatter.category))];
    const filteredPosts = posts.filter(createFilter(this.state.searchTerm, [
      'node.frontmatter.title',
      'node.frontmatter.category',
      'node.excerpt'
    ])).filter(({node: post}) => !this.state.selectedCategory || (this.state.selectedCategory && post.frontmatter.category === this.state.selectedCategory));

    return (
      <div className={'blog-roll'}>
        <div className={'controls'}>
          <SearchInput placeholder={'Search everything'} className="search-input" onChange={this.searchUpdated}/>
          <div className={'filter-bar'}>
            <Dropdown options={[...categories, {label: 'All', value: ''}]}
                      onChange={this.selectCategory}
                      className={'categories'}
                      controlClassName={'category-select'}
                      arrowClassName='arrow'
                      value={this.state.selectedCategory === '' ? null : this.state.selectedCategory}
                      placeholder="Take Your Pick"/>
          </div>
        </div>
        <div className={'desktop-layout'}>
          <div className={'desktop-categories'}>
            <h3>Take Your Pick</h3>
            <ul>
              <li className={this.state.selectedCategory === '' ? 'active' : ''}
                  onClick={() => this.selectCategory('')}>All
              </li>
              {categories.map(category => <li key={category} className={this.state.selectedCategory === category ? 'active' : ''}
                                              onClick={() => this.selectCategory({value: category})}>{category}</li>)}
            </ul>
          </div>
          <div className={'posts'}>
            {filteredPosts &&
            filteredPosts.map(({node: post}) => (
              <div className={'blog-post'} key={post.id}>
                <article>
                  <header>
                    <h3>
                      <Link className={'blog-excerpt-title'} to={post.fields.slug}>
                        {post.frontmatter.title}
                      </Link>
                      <FiChevronRight className={'blog-excerpt-title-icon'} size={'2rem'}/>
                    </h3>
                    <p className="post-meta">
                      By <span className={'post-meta-author'}> {post.frontmatter.author}</span>
                      <span className={'post-meta-date'}><FiClock/> {post.frontmatter.date}</span>
                      Posted in: <span className={'post-meta-category'}>{post.frontmatter.category}</span>
                    </p>
                    {post.frontmatter.featuredimage ? (
                      <div className="featured-thumbnail">
                        <Img fixed={post.frontmatter.featuredimage.childImageSharp.fixed}
                             objectFit="cover"
                             objectPosition="50% 50%"
                             alt={`featured image thumbnail for post ${post.frontmatter.title}`}/>
                      </div>
                    ) : null}
                  </header>
                  <p className={'content'}>
                    {post.excerpt}
                    <Link className="button" to={post.fields.slug}>
                      read more
                    </Link>
                  </p>
                </article>
                <div className={'social'}>
                  <div className="sharethis-inline-share-buttons"
                       data-url={`https://www.littlerassociates.com${post.fields.slug}`}
                       data-title={post.frontmatter.title}
                       data-description={post.excerpt}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }

  searchUpdated(term) {
    this.setState({searchTerm: term})
  }

  selectCategory(option) {
    this.setState({selectedCategory: option.value});
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMM DD, YYYY")
                category
                author
                featuredimage {
                  childImageSharp {
                    fixed( height: 200) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count}/>}
  />
)
