import React from 'react'

import Layout from '../../components/Layout'
import BlogRoll from '../../components/BlogRoll'

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <div className={'container blog-roll'}>
          <div>
            <h1 className={'title'}>Blog</h1>

          </div>
          <div className="content">
            <BlogRoll/>
          </div>
        </div>
      </Layout>
    )
  }
}
